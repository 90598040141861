import React from 'react'; // , { useState, useEffect, useContext }

// react theme/style
import { makeStyles } from '@material-ui/core'; // useTheme,

// MUI core
import { Grid } from '@material-ui/core'; // Link, Button, , Paper, Avatar, Typography

// MUI icons
//

// Gatsby
//import { graphql } from 'gatsby'; // Link,

// 3rd party pkgs: nprogress, table, AutoRotatingCarousel
import Helmet from 'react-helmet';

// Local : see src/index.js for available components
import {
  //Hero,
  //Purpose,
  //Slides,
  //PainBenefit,
  //TestimonialsDisplay,
  //SiteWebPageLayout,
  AnimatedImage,
  SEO,
  SeoInfo,
  SEOJsonLd,
} from '../../src';

const useStyles = makeStyles(theme => {
  return {
    section: {
      height: '40rem',
    },
    waveContainer: {
      position: 'relative',
      background: '#09F',
      color: '#FFF',
      textAlign: 'center',
      overflow: 'hidden',
      '& > svg': {
        display: 'block', // display: inline-block; and vertical-align: middle;
      },
    },
    redirect: {
      textAlign: 'center',
    },
  };
});

//
// Compose Page Sections
//
const IndexPage = props => {
  //const theme = useTheme();
  const classes = useStyles();

  //const imagesHero = props.data.hero.edges;

  // window.location.search : "?site=bitigram.com"
  // https://emert.us   OR    https://emert.me   OR    https://crudli.info etc.,
  //let currentSite; // = typeof window !== 'undefined' ? window.siteurl : 'unknown';
  let currentSite = typeof window !== 'undefined' ? window.location.host : 'unknown';

  if (typeof window !== 'undefined' && window.location.search.startsWith('?site=')) {
    // For DEV search: "?site=bitigram.com"
    currentSite = typeof window !== 'undefined' ? window.location.search.substring(6) : 'unknown';
  }
  if (typeof window !== 'undefined' && window.location.protocol === 'https:') {
    // For PROD host: communityofferings.com
    currentSite = typeof window !== 'undefined' ? window.location.host : 'unknown';
  }

  if (typeof window !== 'undefined')
    console.log('redirect:index.jsx - current host ' + window.location.host);

  console.log('redirect:index.jsx - handle currentSite: ' + currentSite);

  if (currentSite && currentSite.indexOf('-') !== -1) {
    // something like: communityofferings-com.localhost or www-communityofferings-com.localhost
    const t = currentSite.replaceAll('-', '.');
    currentSite = t.substring(0, t.indexOf('.localhost'));
    console.log('redirect:index.jsx - handle modified currentSite: ' + currentSite);
  }

  let seoTitle = '';
  let seoUrl = '';
  let bgimage = '/images/hero/bg.jpg';
  let gradient = '#cc3322';
  let textColor = '#ff0000';
  let name = 'crudLi';
  let title = 'Welcome to my site';
  let subtitle = 'The site is for xxx';
  let content =
    'And as time progresses we will provide you with an easy solution to automate your ability to capture data, process data and display or modify the data.';

  let siteHtml = <h3>{currentSite}</h3>;

  switch (currentSite) {
    //
    // bitigram
    // Landing and Redirect to bitigram.com
    //
    case 'bitigram.com':
    case 'bitigram.net':
    case 'bitigram.org':
    case 'www.bitigram.com':
    case 'www.bitigram.net':
    case 'www.bitigram.org':
      bgimage = '/images/hero/bitigram.jpg';
      gradient = '#ff8800';
      textColor = '#ffffff';
      name = 'BitiGram';
      title = 'Fully secure multi-media and document messaging platform';
      subtitle = 'Share bits of business work flow with your organization and business partners';
      content =
        'Add custom generated internet forms to capture any data. Read, list or display data in a format of your choice. Colloborate with your employees and business partners to update or share your data instantly using secure messaging platform. All without writing a single line of code. Coming Soon.';
      seoTitle = `${title}.`;
      seoUrl = `https://${currentSite}/`;
      siteHtml = (
        <AnimatedImage
          type="soon"
          bgimage={bgimage}
          gradient={gradient}
          textColor={textColor}
          name={name}
          title={title}
          subtitle={subtitle}
          content={content}
        />
      );
      break;

    //
    // chitlet
    // For Sale
    //
    case 'chitlet.com':
    case 'chitlet.net':
    case 'chitlet.org':
    case 'www.chitlet.com':
    case 'www.chitlet.net':
    case 'www.chitlet.org':
      bgimage = '/images/hero/chitlet.jpg';
      gradient = '#000000';
      textColor = '#ffffff';
      name = 'ChitLet';
      title = 'This Domain Is For Sale';
      subtitle = 'Includes:';
      subtitle = 'ChitLet - A small app for an official note, memorandum or voucher';
      content = 'Includes: ChitLet.net ChitLet.org';
      seoTitle = `${title}. It includes: ${content} `;
      seoUrl = `https://${currentSite}/`;
      siteHtml = (
        <AnimatedImage
          type="sale"
          bgimage={bgimage}
          gradient={gradient}
          textColor={textColor}
          name={name}
          title={title}
          subtitle={subtitle}
          content={content}
        />
      );
      break;

    //
    // CommunityOfferings
    // For Sale
    //
    case 'communityofferings.com':
    case 'communityofferings.info':
    case 'communityofferings.net':
    case 'communityofferings.org':
    case 'www.communityofferings.com':
    case 'www.communityofferings.info':
    case 'www.communityofferings.net':
    case 'www.communityofferings.org':
      bgimage = '/images/hero/communityofferings.jpg';
      gradient = '#D4AF37';
      textColor = '#ffffff';
      name = 'CommunityOfferings';
      title = 'This Domain Is For Sale';
      subtitle = 'Includes:';
      content =
        'CommunityOfferings.com CommunityOfferings.info CommunityOfferings.net CommunityOfferings.org';
      seoTitle = `${title}. It includes: ${content} `;
      seoUrl = `https://${currentSite}/`;
      siteHtml = (
        <AnimatedImage
          type="sale"
          bgimage={bgimage}
          gradient={gradient}
          textColor={textColor}
          name={name}
          title={title}
          subtitle={subtitle}
          content={content}
        />
      );
      break;

    //
    // crudli
    // Landing and Redirect to crudli.com
    //
    case 'crudli.com':
    case 'crudli.net':
    case 'crudli.org':
    case 'crud.li':
    case 'www.crudli.com':
    case 'www.crudli.net':
    case 'www.crudli.org':
    case 'www.crud.li':
      bgimage = '/images/hero/crudli.jpg';
      gradient = '#ff8800';
      textColor = '#ffffff';
      name = currentSite.startsWith('crud') ? 'crudLi' : 'MsgForm';
      title = 'Cloud Data Store';
      subtitle = 'Create, Read, Update, Delete and List your data from Cloud Data Store';
      content =
        'Access custom generated internet forms to capture, read, list or display in any format of your choice and instantly share it with your employees and business partners. All without writing a single line of code. Coming Soon.';
      seoTitle = `${title}.`;
      seoUrl = `https://${currentSite}/`;
      siteHtml = (
        <AnimatedImage
          type="soon"
          bgimage={bgimage}
          gradient={gradient}
          textColor={textColor}
          name={name}
          title={title}
          subtitle={subtitle}
          content={content}
        />
      );
      break;

    //
    // dojoinus
    // For Sale - WORKS
    //
    case 'dojoin.us':
    case 'dojoinus.com':
    case 'www.dojoin.us':
    case 'www.dojoinus.com':
      bgimage = '/images/hero/dojoinus.jpg';
      gradient = '#000088';
      textColor = '#ffffff';
      name = 'DoJoinUs';
      title = 'This Domain Is For Sale';
      subtitle = 'Includes:';
      content = 'DoJoinUs.com AND DoJoin.us';
      seoTitle = `${title}. It includes: ${content} `;
      seoUrl = `https://${currentSite}/`;
      siteHtml = (
        <AnimatedImage
          type="sale"
          bgimage={bgimage}
          gradient={gradient}
          textColor={textColor}
          name={name}
          title={title}
          subtitle={subtitle}
          content={content}
        />
      );
      break;

    //
    // easywebify
    // Landing and Redirect to easywebify.com
    //
    case 'easywebify.com':
    case 'easywebify.info':
    case 'easywebify.net':
    case 'easywebify.org':
    case 'www.easywebify.com':
    case 'www.easywebify.info':
    case 'www.easywebify.net':
    case 'www.easywebify.org':
      bgimage = '/images/hero/easywebify.jpg';
      gradient = '#000000';
      textColor = '#ffffff';
      name = 'EasyWebify';
      title = 'Power of Static Website and Dynamic Forms';
      subtitle = 'Integrate custom generated forms with your super fast static website';
      content =
        'Add custom generated internet forms to capture any data. Read, list or display data in a format of your choice. Colloborate with your employees and business partners to update or share your data instantly. All of these without writing a single line of code. Coming Soon.';
      seoTitle = `${title}.`;
      seoUrl = `https://${currentSite}/`;
      siteHtml = (
        <AnimatedImage
          type="soon"
          bgimage={bgimage}
          gradient={gradient}
          textColor={textColor}
          name={name}
          title={title}
          subtitle={subtitle}
          content={content}
        />
      );
      break;

    //
    // emert
    // Landing and Redirect to emert.org
    //
    case 'emert.me':
    case 'emert.us':
    case 'www.emert.me':
    case 'www.emert.us':
      siteHtml = (
        <>
          <h1 className={classes.redirect}>
            VISIT US @ <a href="https://emert.org">emert.org</a>
          </h1>
        </>
      );
      break;

    //
    // hardevi
    // Coming Soon
    //
    case 'hardevi.com':
    case 'www.hardevi.com':
      bgimage = '/images/hero/hardevi.jpg';
      gradient = '#ff8800';
      textColor = '#ffffff';
      name = 'Hardevi';
      title = 'Coming Soon - stay tuned';
      subtitle = '';
      content = '';
      seoTitle = `${title}.`;
      seoUrl = `https://${currentSite}/`;
      siteHtml = (
        <AnimatedImage
          type="soon"
          bgimage={bgimage}
          gradient={gradient}
          textColor={textColor}
          name={name}
          title={title}
          subtitle={subtitle}
          content={content}
        />
      );
      break;

    //
    // inetgram
    // Landing and Redirect to bitigram.com
    //
    case 'inetgram.com':
    case 'inetgram.net':
    case 'inetgram.org':
    case 'www.inetgram.com':
    case 'www.inetgram.net':
    case 'www.inetgram.org':
      bgimage = '/images/hero/inetgram.jpg';
      gradient = '#ff8800';
      textColor = '#ffffff';
      name = 'InetGram';
      title = 'Fully secure multi-media and document messaging platform';
      subtitle = 'Share bits of business work flow with your organization and business partners';
      content =
        'Add custom generated internet forms to capture any data. Read, list or display data in a format of your choice. Colloborate with your employees and business partners to update or share your data instantly using secure messaging platform. All without writing a single line of code. Coming Soon.';
      seoTitle = `${title}. `;
      seoUrl = `https://${currentSite}/`;
      siteHtml = (
        <AnimatedImage
          type="soon"
          bgimage={bgimage}
          gradient={gradient}
          textColor={textColor}
          name={name}
          title={title}
          subtitle={subtitle}
          content={content}
        />
      );
      break;

    //
    // kripa
    // Landing and Redirect to kripa.com
    //
    case 'kripa.info':
    case 'www.kripa.info':
      siteHtml = (
        <>
          <h1 className={classes.redirect}>
            VISIT US @ <a href="https://kripa.com">kripa.com</a>
          </h1>
        </>
      );
      break;

    //
    // msgform
    // Landing and Redirect to crudli.com
    //
    case 'msgform.com':
    case 'msgform.info':
    case 'msgform.me':
    case 'msgform.net':
    case 'msgform.org':
    case 'msgform.us':
    case 'www.msgform.com':
    case 'www.msgform.info':
    case 'www.msgform.me':
    case 'www.msgform.net':
    case 'www.msgform.org':
    case 'www.msgform.us':
      bgimage = '/images/hero/msgform.jpg';
      gradient = '#000000';
      textColor = '#ffffff';
      name = currentSite.startsWith('crud') ? 'crudLi' : 'MsgForm';
      title = 'Cloud Data Instant Messaging';
      subtitle = 'Create, Read, Update, Delete and List your data from Cloud Messaging';
      content =
        'Access custom generated internet forms to capture any data. Read, list or display data in a format of your choice. Use internet messaging with your employees and business partners to update or share your data instantly. All of these without writing a single line of code. Coming Soon.';
      seoTitle = `${title}. `;
      seoUrl = `https://${currentSite}/`;
      siteHtml = (
        <AnimatedImage
          type="soon"
          bgimage={bgimage}
          gradient={gradient}
          textColor={textColor}
          name={name}
          title={title}
          subtitle={subtitle}
          content={content}
        />
      );
      break;

    //
    // msgform
    // Landing and Redirect to crudli.com
    //
    case 'nullic.com':
    case 'nullic.info':
    case 'nullic.me':
    case 'nullic.net':
    case 'nullic.org':
    case 'nullic.us':
    case 'www.nullic.com':
    case 'www.nullic.info':
    case 'www.nullic.me':
    case 'www.nullic.net':
    case 'www.nullic.org':
    case 'www.nullic.us':
      /*
    siteHtml = <><h1 className={classes.redirect}>VISIT US @ <a href="https://nullic.com">nullic.com</a></h1></>;
  */

      bgimage = '/images/hero/nullic.jpg';
      gradient = '#000000';
      textColor = '#ffffff';
      name = 'Nullic';
      title = 'Blog Site for those interested in spirituality';
      subtitle = '';
      content = '';
      seoTitle = `${title}.`;
      seoUrl = `https://${currentSite}/`;
      siteHtml = (
        <AnimatedImage
          type="soon"
          bgimage={bgimage}
          gradient={gradient}
          textColor={textColor}
          name={name}
          title={title}
          subtitle={subtitle}
          content={content}
        />
      );

      break;

    //
    // peplet
    // For Sale
    //
    case 'peplet.com':
    case 'peplet.info':
    case 'peplet.me':
    case 'peplet.net':
    case 'peplet.org':
    case 'peplet.us':
    case 'www.peplet.com':
    case 'www.peplet.info':
    case 'www.peplet.me':
    case 'www.peplet.net':
    case 'www.peplet.org':
    case 'www.peplet.us':
      bgimage = '/images/hero/peplet.jpg';
      gradient = '#333333';
      textColor = '#ffffff';
      name = 'PepLet';
      title = 'This Domain Is For Sale';
      subtitle = 'PepLet - Active and lively small app';
      content = 'Includes: PepLet.com PepLet.info PepLet.me PepLet.net PepLet.org PepLet.us';
      seoTitle = `${title}. It includes: ${content} `;
      seoUrl = `https://${currentSite}/`;
      siteHtml = (
        <AnimatedImage
          type="sale"
          bgimage={bgimage}
          gradient={gradient}
          textColor={textColor}
          name={name}
          title={title}
          subtitle={subtitle}
          content={content}
        />
      );
      break;

    //
    // uniys
    // For Sale
    //
    case 'uniys.com':
    case 'uniys.net':
    case 'uniys.org':
    case 'www.uniys.com':
    case 'www.uniys.net':
    case 'www.uniys.org':
      bgimage = '/images/hero/uniys.jpg';
      gradient = '#000000';
      textColor = '#ffffff';
      name = 'uniys';
      title = 'This Domain Is For Sale';
      subtitle = 'Includes:';
      content = 'uniys.com, uniys.net and uniys.org';
      seoTitle = `${title}. It includes: ${content} `;
      seoUrl = `https://${currentSite}/`;
      siteHtml = (
        <AnimatedImage
          type="sale"
          bgimage={bgimage}
          gradient={gradient}
          textColor={textColor}
          name={name}
          title={title}
          subtitle={subtitle}
          content={content}
        />
      );
      break;

    default:
      siteHtml = (
        <>
          <h1>{currentSite}</h1>
          <h2>Unknown Site</h2>
        </>
      );
  }
  /*
    <SiteWebPageLayout>
      <SEO { ...seoargs }/>
      <Grid item xs={12} id='hero' >
	Hero
      </Grid>

      <Grid item xs={12} id='hero'>
	{siteHtml}
      </Grid>
    </SiteWebPageLayout>

*/

  //Can't do SEO processing as this gatsby project handles multiple web apps
  //const seoInfo = new SeoInfo('WebIndexPage', null, null, currentSite).obj;

  return (
    <>
      {/* <SEO seoInfo = { seoInfo }/> */}
      <Helmet
        htmlAttributes={{ lang: 'en' }}
        title={seoTitle}
        link={[{ rel: 'canonical', href: seoUrl }]}
      />
      <Grid item xs={12} id="hero">
        {siteHtml}
      </Grid>
    </>
  );
};

/*
      <Grid item xs={12} id='hero'>
        <Hero images={imagesHero} />
      </Grid>



<svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
  <path fill="#08BDBA" d="M28.1,-44.5C38.4,-31.2,50,-25.6,52.6,-17.2C55.1,-8.8,48.5,2.4,44.2,13.9C39.8,25.4,37.7,37.2,30.7,45.7C23.7,54.2,11.9,59.5,2.3,56.3C-7.2,53.2,-14.5,41.5,-18.7,32.1C-22.8,22.6,-23.9,15.4,-32.3,6.5C-40.6,-2.3,-56.2,-12.8,-57.1,-21.2C-58.1,-29.6,-44.4,-35.9,-32.4,-48.6C-20.4,-61.3,-10.2,-80.3,-0.7,-79.4C8.9,-78.5,17.8,-57.7,28.1,-44.5Z" transform="translate(100 100)" />
</svg>

  <svg width="100%" height="100%">
    <rect width="100%" height="100%" fill="red" />
    <circle cx="100%" cy="100%" r="150" fill="blue" stroke="black" />
    <polygon points="120,0 240,225 0,225" fill="green"/>
    <text x="50" y="100" font-family="Verdana" font-size="55"
          fill="white" stroke="black" stroke-width="2">
            Hello!
    </text>
  </svg>
*/

/*
export const query = graphql`
  {
    hero: allFile(
      filter: { sourceInstanceName: { eq: "images" }, relativePath: { glob: "hero/*" } }
    ) {
      edges {
        node {
          relativePath
          childImageSharp {
            fluid(maxWidth: 300, maxHeight: 400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
*/

export default IndexPage;
